import animateScrollTo from "animated-scroll-to";
import { motion } from "framer-motion";
import { shallow } from "zustand/shallow";

import ConditionalWrapper from "@/components/utils/ConditionalWrapper";
import { DUR_MD, DUR_SM } from "@/config";
import { useHashStore } from "@/lib/store";

const HomeLabel = ({ project, isActive, wasActive, iPage }) => {
  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: !wasActive ? 0 : 1 },
    exit: { opacity: 0 },
  };

  const { setProject, setInfo, route } = useHashStore(
    (state) => state,
    shallow,
  );

  return (
    <>
      <motion.div
        className="HomeLabel"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        transition={{
          duration: DUR_MD,
          delay: DUR_SM * 2,
          ease: "easeOut",
        }}
        data-active={wasActive ? (isActive ? undefined : "after") : "before"}
        onMouseDown={() =>
          !wasActive &&
          animateScrollTo(document?.querySelectorAll(".HomeItem")?.[iPage], {
            elementToScroll: document?.querySelectorAll(".Home")?.[0],
            verticalOffset: -50,
            cancelOnUserAction: false,
          })
        }
      >
        <ConditionalWrapper
          condition={false}
          wrapper={(children) => (
            <button
              onMouseDown={() => {
                setProject(isActive ? project.slug?.current : null);
                setInfo(null);
              }}
            >
              {children}
            </button>
          )}
        >
          <motion.div
            layout
            layoutRoot
            key={project?.slug}
            data-active={wasActive || isActive}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            transition={{
              duration: DUR_MD,
              delay: wasActive ? 0 : DUR_SM * 3,
              ease: "easeOut",
            }}
          >
            <motion.span className="clients" layout>
              {project.clients?.map(({ name }) => name)?.join(", ")}
            </motion.span>

            <motion.div
              className="wrapper"
              initial={{ width: "0px" }}
              animate={{ width: wasActive ? "auto" : "0px" }}
              transition={{
                delay: wasActive || isActive ? DUR_SM : 0,
                duration: DUR_SM,
                ease: "easeOut",
              }}
              key="wrapper"
            >
              <motion.span
                className="season"
                key="season"
                {...animation}
                transition={{ delay: !wasActive ? 0 : 1 * 0.075 + DUR_SM * 2 }}
              >
                {project.season?.season}
                {project.season?.year % 100}
              </motion.span>

              <motion.span
                className="tags"
                key="tags"
                {...animation}
                transition={{ delay: !wasActive ? 0 : 2 * 0.075 + DUR_SM * 2 }}
              >
                {project.tags?.map(({ title }) => title)?.join(", ")}
              </motion.span>

              <motion.span
                className="location"
                key="location"
                {...animation}
                transition={{ delay: !wasActive ? 0 : 3 * 0.075 + DUR_SM * 2 }}
              >
                {[project.location?.city, project.location?.country]?.join(
                  ", ",
                )}
              </motion.span>
            </motion.div>
          </motion.div>
        </ConditionalWrapper>
      </motion.div>
      <style jsx global>{`
        .HomeLabel {
          position: fixed;
          top: calc((50 * var(--svh)) - (var(--lh-md) + var(--dist-md)));
          left: 50%;
          width: auto;
          max-width: calc(100% - 2 * var(--dist-md));
          transform: translateX(-50%);
          transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);
          z-index: 999;
          contain: layout paint style;
          content-visibility: auto;
          contain-intrinsic-size: calc(2 * var(--dist-md) * var(--lh-md));
        }

        .HomeLabel > *:has(> span) {
          background: var(--bg-glass);
          border-radius: 1px;
          backdrop-filter: blur(3px) brightness(0.9) hue-rotate(120deg);
          display: flex;
          padding: var(--dist-md);
          transition: backdrop-filter 0.4s;
        }

        @media (hover: hover) {
          .HomeLabel:hover > *:has(> span) {
            backdrop-filter: blur(3px) brightness(0.6) hue-rotate(120deg)
              contrast(1.2);
          }
        }

        .HomeLabel:has([data-active="true"]) {
          backdrop-filter: blur(3px) brightness(0.6) hue-rotate(120deg)
            contrast(1.2);
        }

        .HomeLabel .wrapper {
          display: flex;
          gap: var(--dist-md);
          overflow: hidden;
        }

        .HomeLabel .wrapper > span:first-child {
          margin-left: var(--dist-md);
        }

        .HomeLabel span.clients {
        }

        .HomeLabel span {
          display: block;
          overflow: hidden;
          white-space: nowrap;
        }

        .HomeLabel :is(.season, .location) {
          flex-shrink: 0;
        }

        .HomeLabel[data-active="before"] {
          top: calc(100 * var(--svh) - (var(--dist-md) * 3 + var(--lh-md)));
          pointer-events: none;
          opacity: 0 !important;
        }

        .HomeLabel:not(:is([data-active="before"], [data-active="after"]))
          + .HomeLabel[data-active="before"] {
          opacity: 1 !important;
          pointer-events: auto;
        }

        .HomeLabel:not(:is([data-active="before"], [data-active="after"])) {
          pointer-events: none;
        }

        .HomeLabel[data-active="after"] {
          top: calc(-100 * var(--svh));
          opacity: 0;
        }
      `}</style>
    </>
  );
};

export default HomeLabel;
