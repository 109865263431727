import { motion } from "framer-motion";

import Media from "@/components/shared/Media";
import { DUR_MD } from "@/config";

const HomeContainer = ({ projects, pos, iPage }) => {
  const iThumbnail = pos === "left" ? "thumbnail0" : "thumbnail1";

  return (
    <>
      <motion.section
        className="HomeContainer"
        data-pos={pos}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        transition={{
          duration: DUR_MD,
          delay: DUR_MD,
          ease: "easeOut",
        }}
      >
        {projects?.map(({ thumbnails }, i) => {
          const isActive = i === iPage,
            wasActive = i <= iPage;

          return (
            <motion.div
              key={i}
              className="preview"
              whileInView={{ visibility: "visible" }}
              initial={{ visibility: "hidden" }}
              data-active={
                wasActive ? (isActive ? undefined : "after") : "before"
              }
            >
              <Media
                media={thumbnails?.[iThumbnail]}
                sizes="(max-aspect-ratio: 3/4) calc(100vw * max(1, var(--ratio))), 50vw"
              />
            </motion.div>
          );
        })}
      </motion.section>

      <style jsx global>{`
        .HomeContainer {
          position: fixed;
          top: 0;
          left: 0;
          width: 50%;
          height: calc(100 * var(--svh));
          z-index: 1;
          pointer-events: none;
          overflow: hidden;
          background: transparent;
        }

        .HomeContainer .Media mux-player {
          --controls: none;
        }

        .HomeContainer .preview .Media {
          position: absolute;
          transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);
          z-index: 777;
          contain: layout paint style;
          content-visibility: auto;
          pointer-events: auto;
        }

        .HomeContainer[data-pos="left"] .preview[data-active="before"] .Media {
          left: var(--dist-md);
          z-index: 9999;
        }

        .HomeContainer[data-pos="right"] .preview[data-active="before"] .Media {
          right: var(--dist-md);
        }

        .HomeContainer
          .preview[data-active="before"]
          + .preview[data-active="before"]
          .Media {
          opacity: 0;
          visibility: hidden;
          bottom: -100%;
        }

        @media (max-aspect-ratio: 3/4) {
          .HomeContainer {
            width: 100%;
            z-index: 99;
            overflow: hidden;
          }

          .HomeContainer[data-pos="right"] {
            z-index: 98;
          }

          .HomeContainer[data-pos="left"] .preview .Media {
            will-change: width, height, bottom, left;
            left: 0;
          }
          .HomeContainer[data-pos="right"] .preview .Media {
            right: 0;
            will-change: width, height, bottom, right;
          }

          .HomeContainer .preview[data-active="after"] .Media {
            width: calc(100vw - var(--w-scrollbar));
            height: calc((100vw - var(--w-scrollbar)) / var(--ratio));
            position: absolute;
            bottom: calc(100 * var(--svh));
            z-index: 999;
            opacity: 0;
            contain-intrinsic-size: calc(
              (100vw - var(--w-scrollbar)) / var(--ratio)
            );
          }

          .HomeContainer
            .preview:not(:is([data-active="before"], [data-active="after"]))
            .Media {
            height: calc(50 * var(--svh));
            --w: calc((50 * var(--svh)) * var(--ratio));
            width: var(--w);
          }

          .HomeContainer[data-pos="left"]
            .preview:not(:is([data-active="before"], [data-active="after"]))
            .Media {
            bottom: calc(50 * var(--svh));
            left: calc(50% - var(--w) / 2);
          }
          .HomeContainer[data-pos="right"]
            .preview:not(:is([data-active="before"], [data-active="after"]))
            .Media {
            bottom: 0;
            right: calc(50% - var(--w) / 2);
          }

          .HomeContainer .preview[data-active="before"] .Media {
            width: calc(
              (2 * var(--dist-md) + var(--lh-md)) * 1.5 * var(--ratio)
            );
            height: calc((2 * var(--dist-md) + var(--lh-md)) * 1.5);
            position: absolute;
            bottom: var(--dist-md);
            z-index: 1111;
            contain-intrinsic-size: calc(
              (2 * var(--dist-md) + var(--lh-md)) * 2
            );
          }

          .HomeContainer[data-pos="left"] .preview[data-active="after"] .Media {
            left: 0;
          }

          .HomeContainer[data-pos="right"]
            .preview[data-active="after"]
            .Media {
            right: 0;
          }
        }

        @media (min-aspect-ratio: 3/4) {
          .HomeContainer {
          }

          .HomeContainer[data-pos="right"] {
            left: 50%;
          }

          .HomeContainer[data-pos="left"] .preview .Media {
            will-change: width, height, bottom, left;
            left: 0;
          }
          .HomeContainer[data-pos="right"] .preview .Media {
            right: 0;
            will-change: width, height, bottom, right;
          }

          .HomeContainer .preview[data-active="after"] .Media {
            width: 100%;
            aspect-ratio: var(--ratio);
            position: absolute;
            bottom: 100%;
            z-index: 666;
            contain-intrinsic-size: calc(
              (50vw - var(--w-scrollbar) / 2) / var(--ratio)
            );
          }

          .HomeContainer
            .preview:not(:is([data-active="before"], [data-active="after"]))
            .Media {
            width: 100%;
            aspect-ratio: var(--ratio);
            --h: calc((50vw - var(--w-scrollbar) / 2) / var(--ratio));
            height: var(--h);
            bottom: calc(50% - var(--h) / 2);
            contain-intrinsic-size: var(--h);
          }

          .HomeContainer .preview[data-active="before"] .Media {
            width: calc((2 * var(--dist-md) + var(--lh-md)) * 2 * var(--ratio));
            height: calc((2 * var(--dist-md) + var(--lh-md)) * 2);
            position: absolute;
            bottom: var(--dist-md);
            z-index: 888;
            contain-intrinsic-size: calc(
              (2 * var(--dist-md) + var(--lh-md)) * 2
            );
          }

          .HomeContainer[data-pos="left"] .preview[data-active="after"] .Media {
            left: 0;
          }

          .HomeContainer[data-pos="right"]
            .preview[data-active="after"]
            .Media {
            right: 0;
          }
        }
      `}</style>
    </>
  );
};

export default HomeContainer;
