import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIdleTimer } from "react-idle-timer";
import usePortal from "react-useportal";
import slugify from "slugify";
import { shallow } from "zustand/shallow";

import Media from "@/components/shared/Media";
import { DUR_MD, DUR_SM } from "@/config";
import useWindowSize from "@/hooks/useWindowSize";
import { useHashStore } from "@/lib/store";

const ProjectsList = ({ projects }) => {
  const { Portal } = usePortal();

  const [iPreview, setPreview] = useState(null);

  const { setProject, setInfo, category, ...hashState } = useHashStore(
    (state) => state,
    shallow,
  );

  const ref = useRef(),
    refScroll = useRef();

  const [isIdle, setIdle] = useState(true);

  const onIdle = () => {
      setIdle(true);
    },
    onActive = () => {
      setIdle(false);
    },
    { getRemainingTime } = useIdleTimer({
      onIdle,
      onActive,
      timeout: 1000,
      throttle: 100,
      events: ["mousemove"],
    });

  const { windowW, windowH } = useWindowSize();

  const hBar = 41,
    yTop = 15,
    yGap = 6,
    hLi = hBar;
  const hNav =
    windowW < 768
      ? yTop + hBar + yGap + hBar + yTop + hLi / 2
      : yTop + hBar + yTop + hLi / 2;

  const margin = `-${hNav}px 0px -${windowH - (hNav + 20)}px 0px`;

  return (
    <>
      <motion.article
        className="ProjectsList"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        transition={{
          duration: DUR_MD,
          delay: DUR_SM,
          ease: "easeOut",
        }}
        ref={ref}
        data-idle={isIdle}
        key={category}
      >
        <motion.ul ref={refScroll}>
          {projects?.map(
            ({ _id, thumbnails, slug, clients, location, tags, season }, i) => {
              const categories = [
                ...new Set(tags?.map(({ category }) => category?.title).flat()),
              ];

              return category === "all" ||
                categories
                  ?.map((c) => slugify(c, { lower: true }))
                  ?.includes(category) ? (
                <Fragment key={windowW < 768 ? _id + "-sm" : _id}>
                  <motion.li
                    key={_id}
                    data-i={iPreview}
                    data-active={i === iPreview}
                    onHoverStart={() =>
                      iPreview !== i && !isIdle && setPreview(i)
                    }
                    onHoverEnd={() =>
                      iPreview === i && !isIdle && setPreview(null)
                    }
                    onViewportEnter={() => {
                      setPreview(i);
                    }}
                    viewport={{
                      margin,
                      amount:
                        i < projects?.length - 1 ? (i === 0 ? 0.1 : 0.33) : 0,
                    }}
                  >
                    <button
                      onMouseDown={() => {
                        setProject(slug?.current);
                        setInfo(null);
                      }}
                    >
                      <span className="clients">
                        {clients?.map(({ name }) => name)?.join(", ")}
                      </span>
                      <span>
                        {season?.season}
                        {season?.year % 100}
                      </span>
                      <span>{categories?.join(", ")}</span>
                      <span className="client">
                        {[location?.city, location?.country]?.join(", ")}
                      </span>
                    </button>
                  </motion.li>

                  <Portal>
                    <motion.div
                      className="ProjectsListPreview"
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: iPreview === i && !hashState?.info ? 1 : 0,
                        zIndex: iPreview === i && !hashState?.info ? 1 : 0,
                      }}
                      exit={{ opacity: 0 }}
                      transition={{
                        duration: !iPreview ? 0 : iPreview === i ? 0 : 0.233,
                      }}
                    >
                      <Media
                        className="ProjectsListMedia"
                        media={thumbnails?.["thumbnail0"]}
                        sizes="calc(20vw * var(--ratio))"
                        mode="gif"
                      />
                      <Media
                        className="ProjectsListMedia"
                        media={thumbnails?.["thumbnail1"]}
                        sizes="calc(20vw * var(--ratio))"
                        mode="gif"
                      />
                    </motion.div>
                  </Portal>
                </Fragment>
              ) : null;
            },
          )}
        </motion.ul>
      </motion.article>
      <style jsx global>{`
        .ProjectsList {
        }

        .ProjectsList > ul {
          height: calc((100 * var(--svh)));
          padding-top: calc(var(--global-offset-y));
          will-change: padding-top;
          transition: ${DUR_SM}s padding-top 0s cubic-bezier(0.87, 0, 0.13, 1);
          overflow: auto;
          overscroll-behavior: contain;
        }

        .ProjectsList li {
          contain: layout paint style;
          content-visibility: auto;
          contain-intrinsic-size: calc(2 * var(--dist-md) * var(--lh-md));
        }

        .ProjectsList li:last-child {
          padding-bottom: calc(
            (100 * var(--svh)) - var(--global-offset-y) - 2 * var(--dist-md)
          );
        }

        @media (hover: hover) {
          .ProjectsList:hover:has(li:hover) li:not([data-active="true"]) {
            opacity: 0.25;
          }
        }

        @media (hover: none) {
          .ProjectsList li:not([data-active="true"]) {
            opacity: 0.25;
          }
        }

        .ProjectsListMedia {
          width: calc((2 * var(--dist-md) + var(--lh-md)) * 2 * var(--ratio));
          height: calc((2 * var(--dist-md) + var(--lh-md)) * 2);
          position: fixed !important;
          bottom: var(--dist-md);
          left: var(--dist-md);
          z-index: 1111;
          contain: size layout paint style;
          content-visibility: auto;
          contain-intrinsic-size: calc((2 * var(--dist-md) + var(--lh-md)) * 2);
        }

        .ProjectsListMedia + .ProjectsListMedia {
          left: unset;
          right: var(--dist-md);
        }

        .ProjectsList li {
          display: block;
        }

        .ProjectsList button {
          width: 100%;
          padding: var(--dist-md);
          display: grid;
          gap: var(--dist-md);
          grid-template-columns: repeat(4, 1fr);
          pointer-events: auto;
          -webkit-user-drag: none;
        }

        .ProjectsList span.clients {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ProjectsListPreview {
          pointer-events: none;
        }

        @media (hover: none) {
          .ProjectsList > ul {
          }

          .ProjectsList li {
            scroll-margin-top: calc(var(--global-offset-y));
          }
        }
      `}</style>
    </>
  );
};

export default ProjectsList;
