import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { capitalize } from "lodash";
import { useRouter } from "next/router";
import { groq } from "next-sanity";
import { useLiveQuery } from "next-sanity/preview";
import { useEffect } from "react";

import Home from "@/components/pages/home/Home";
import Projects from "@/components/pages/projects/Projects";
import { CATEGORIES, DUR_MD, DUR_SM, PRESENTATION_MODES } from "@/config";
import { readToken } from "@/lib/sanity.api";
import { getClient } from "@/lib/sanity.client";
import { refMedia, refMediaMini } from "@/lib/sanity.queries";
import { useHashStore } from "@/lib/store";

export default function Route(props) {
  const { query, params, draftMode } = props;

  const [data, loading] = useLiveQuery(props?.data, query, params);

  const { category, route, project } = useHashStore((state) => state);

  const router = useRouter();
  useEffect(() => {
    if (!!route && !project) router?.push(route === "selected" ? "/" : route);
  }, [route]);

  useEffect(() => {
    const newURL = [
      window.location.origin,
      !!project ? "project/" + project : route === "selected" ? null : route,
    ]
      ?.filter(Boolean)
      ?.join("/");
    history.replaceState(null, "", newURL);
  }, [project]);

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      transition={{
        duration: DUR_MD,
        delay: DUR_SM,
        ease: "easeOut",
      }}
    >
      {PRESENTATION_MODES?.includes(route) ? (
        <Projects {...(!data?._id ? data : { data })} />
      ) : route === "selected" ? (
        <Home key="home" {...(!data?._id ? data : { data })} />
      ) : null}
    </motion.main>
  );
}

export const getServerSideProps = async (ctx) => {
  const { draftMode = false, params = {}, locale } = ctx;
  const client = getClient(draftMode ? readToken : undefined);

  const queryProperties = `
    _id,
    slug,
    location->{city,country},
    'clients': clients[]{
      _type == 'reference' => @->{name},
      _type != 'reference' => @{name},
    },
    tags[]->{...,category->},
    season->{year, season},
    thumbnails {...,thumbnail0{..., ${refMedia}},thumbnail1{..., ${refMedia}}} 
`;

  const constructQuery = (
    category,
  ) => groq`{"projects": *[_type == "project" && "${category}" in tags[]->category->title]
|order(orderRank)[0..35]{${queryProperties}}, 
"total": count(*[_type == "project" && "${category}" in tags[]->category->title])}`;

  const queries = {
    home: `{"home": *[_type == "home"][0]{projects[]->{${queryProperties}}}.projects}`,

    projects: groq`{"projects": *[_type == "project"]
    |order(orderRank)[0..35]{${queryProperties}},
    "total": count(*[_type == "project"])}`,

    list: groq`{"projects": *[_type == "project"]|order(orderRank){
      _id,
      slug,
      location->{city,country},
      'clients': clients[]{
        _type == 'reference' => @->{name},
        _type != 'reference' => @{name},
      },
      tags[]->{...,category->},
      season->{year, season},
      thumbnails {...,thumbnail0{..., ${refMediaMini}},thumbnail1{..., ${refMediaMini}}} }}`,

    ...Object.fromEntries(
      CATEGORIES.map((category) => [
        category,
        constructQuery(capitalize(category)),
      ]),
    ),
  };

  let query;

  const slug = ctx?.query?.slug?.[0],
    sub = ctx?.query?.slug?.[1];

  if (PRESENTATION_MODES?.includes(slug)) {
    query = queries?.[slug];
  } else if ((slug === "project" && !!sub) || !slug || "info" === slug) {
    query = queries?.["home"];
  } else {
    return {
      notFound: true,
    };
  }

  const [page] = await Promise.all([
    client.fetch(query, { ...params, locale }),
  ]);

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      data: { page },
      draftMode,
      query,
      params: { ...params, locale },
      token: draftMode ? readToken : "",
    },
  };
};
