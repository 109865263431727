import { AnimatePresence, motion } from "framer-motion";
import { memo } from "react";

import { useHashStore } from "@/lib/store";

import ProjectsGrid from "./ProjectsGrid";
import ProjectsList from "./ProjectsList";

const Projects = ({ page, ...props }) => {
  const { projects, total } = page || {};

  const { category, route, ...hashState } = useHashStore((state) => state);

  return (
    <>
      <AnimatePresence mode="wait">
        {route === "list" ? (
          <ProjectsList key={"list"} projects={projects} total={total} />
        ) : (
          <ProjectsGrid key={category} projects={projects} total={total} />
        )}
      </AnimatePresence>

      {/* <style jsx global>{``}</style> */}
    </>
  );
};

export default memo(Projects);
