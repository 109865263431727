import { AnimatePresence, cubicBezier, motion } from "framer-motion";
import { useState } from "react";

import Media from "@/components/shared/Media";
import { DUR_MD, DUR_SM } from "@/config";
const ProjectsGridItem = ({ project, hashState, setInfo, setProject }) => {
  const { _id, thumbnails, slug, clients, location, season, tags } =
    project || {};

  return (
    <>
      <motion.figure
        initial={{ opacity: 0, background: "transparent" }}
        animate={{
          opacity: 1,
          background:
            (thumbnails?.thumbnail0 || thumbnails?.thumbnail1)?.image?.asset
              ?.metadata?.palette?.lightMuted?.background || "#000",
        }}
        exit={{ opacity: 0 }}
        onMouseDown={() => {
          setInfo(false);
          setProject(slug?.current);
        }}
        transition={{
          duration: DUR_SM,
          layout: { duration: DUR_MD, ease: cubicBezier(0.32, 0.72, 0, 1) },
        }}
        style={{ willChange: "transform" }}
        layout="preserve-aspect"
      >
        <motion.div
          key={_id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Media
            key={_id}
            media={thumbnails?.thumbnail0 || thumbnails?.thumbnail1}
            sizes="(max-width: 480px) calc(100vw * max(1, var(--ratio))),
                (min-width: 480px) and (max-width: 768px) calc(50vw * max(1, var(--ratio))),
                (min-width: 768px) and (max-width: 1024px) calc(100vw / 3 * max(1, var(--ratio))),
                (min-width: 1024px) and (max-width: 1440px) calc(100vw / 3 * max(1, var(--ratio))),
                (min-width: 1440px) and (max-width: 1680px) calc(100vw / 4 * max(1, var(--ratio))),
                (min-width: 1680px) 25vw"
          />
        </motion.div>

        <AnimatePresence mode="wait">
          {hashState?.view === "index" ? (
            <motion.figcaption
              key="index"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
              transition={{
                duration: DUR_SM,
                type: "easeOut",
              }}
              whileInView={{ opacity: 0 }}
              viewport={{ margin: "0% 0% -75% 0%" }}
            >
              <motion.span className="clients" key="clients">
                {clients?.map(({ name }) => name)?.join(", ")}
              </motion.span>
              <motion.span className="year" key="year">
                {season?.season}
                {season?.year % 100}
              </motion.span>
              <motion.span className="tags" key="tags">
                {tags?.map(({ title }) => title)?.join(", ")}
              </motion.span>
              <motion.span className="location" key="location">
                {[location?.city, location?.country]?.join(", ")}
              </motion.span>
            </motion.figcaption>
          ) : (
            <motion.figcaption
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: DUR_SM,
                type: "easeOut",
              }}
              whileInView={{ opacity: 0 }}
              viewport={{ margin: "0% 0% -75% 0%" }}
              key="small"
            >
              <motion.span className="clients" key="clients">
                {clients?.map(({ name }) => name)?.join(", ")}
              </motion.span>

              <motion.span className="location" key="location">
                {[location?.city, location?.country]?.join(", ")}
              </motion.span>
            </motion.figcaption>
          )}
        </AnimatePresence>
      </motion.figure>
      {/* <style jsx global>{``}</style> */}
    </>
  );
};

export default ProjectsGridItem;
