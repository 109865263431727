import animateScrollTo from "animated-scroll-to";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { groq } from "next-sanity";
import { useRef, useState } from "react";
import { RxArrowLeft, RxArrowRight } from "react-icons/rx";
import useSWR from "swr";

import { DUR_MD, DUR_SM } from "@/config";
import { getClient } from "@/lib/sanity.client";
import { refMedia } from "@/lib/sanity.queries";
import { useHashStore } from "@/lib/store";

import ProjectsGridItem from "./ProjectsGridItem";

const ProjectsGrid = ({ projects, total }) => {
  const { setInfo, setProject, category, ...hashState } = useHashStore(
    (state) => state,
  );

  const ref = useRef();

  const [page, setPage] = useState(1);
  const [isAnimating, setAnimating] = useState(false);

  const queryProperties = `
  _id,
  slug,
  location->{city,country},
  'clients': clients[]{
    _type == 'reference' => @->{name},
    _type != 'reference' => @{name},
  },
  tags[]->{...,category->},
  season->{year, season},
  thumbnails {...,thumbnail0{..., ${refMedia}},thumbnail1{..., ${refMedia}}}
`;

  const { data, error, isLoading } = useSWR(
    page > 1 &&
      page <= Math.ceil(total / 36) &&
      groq`{"projects": *[_type == "project"]
    |order(orderRank)[${36 * (page - 1)}..${35 + 36 * (page - 1)}]{${queryProperties}}}`,
    (query) => getClient(undefined).fetch(query),
  );

  const projectsPage = page > 1 ? data?.projects : projects;

  return (
    <>
      <motion.article
        className="ProjectsGrid"
        key={"grid"}
        data-view={hashState?.view}
        initial={{ opacity: 0 }}
        animate={{
          opacity: isAnimating ? 0 : 1,
        }}
        exit={{ opacity: 0 }}
        transition={{
          duration: DUR_MD,
          delay: DUR_MD * 2,
          ease: "easeOut",
        }}
        ref={ref}
      >
        <motion.div
          className="wrapper"
          layout
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          transition={{
            duration: DUR_MD,
            ease: "easeOut",
          }}
        >
          <LayoutGroup>
            <AnimatePresence mode="wait">
              <motion.div
                layout
                layoutRoot
                key={page}
                animate={{
                  opacity: isAnimating ? 0 : 1,
                }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: DUR_MD,
                  ease: "easeOut",
                }}
              >
                {projectsPage?.map((project, i) => (
                  <ProjectsGridItem
                    key={project?._id}
                    {...{ project, i, hashState, setInfo, setProject }}
                  />
                ))}
              </motion.div>
            </AnimatePresence>
            {total > 36 && (
              <motion.footer
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: !!isAnimating ? 0 : 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: DUR_SM }}
              >
                <button
                  className="prev"
                  disabled={page === 1}
                  data-disabled={page === 1}
                  onMouseDown={() => {
                    setAnimating(true);
                    setPage((prev) => prev - 1);
                    setTimeout(
                      () =>
                        animateScrollTo(0, {
                          elementToScroll:
                            ref?.current?.querySelectorAll(".wrapper")?.[0],
                          maxDuration: 0,
                          cancelOnUserAction: false,
                        }).then(() =>
                          setTimeout(() => setAnimating(false), 100),
                        ),
                      DUR_MD * 500,
                    );
                  }}
                >
                  <span>
                    <RxArrowLeft />
                  </span>
                </button>
                <button
                  disabled={page >= Math.ceil(total / 36)}
                  data-disabled={page >= Math.ceil(total / 36)}
                  className="next"
                  onMouseDown={() => {
                    setAnimating(true);
                    setPage((prev) => prev + 1);
                    setTimeout(
                      () =>
                        animateScrollTo(0, {
                          elementToScroll:
                            ref?.current?.querySelectorAll(".wrapper")?.[0],
                          maxDuration: 0,
                          cancelOnUserAction: false,
                        }).then(() =>
                          setTimeout(() => setAnimating(false), 100),
                        ),
                      DUR_MD * 500,
                    );
                  }}
                >
                  <span>
                    <RxArrowRight />
                  </span>
                </button>
              </motion.footer>
            )}
          </LayoutGroup>
        </motion.div>
      </motion.article>

      <style jsx global>{`
        .ProjectsGrid a {
          -webkit-user-drag: none;
        }

        .ProjectsGrid > .wrapper {
          overflow: auto;
          overscroll-behavior: contain;
          scroll-snap-type: y mandatory;
          height: calc(100 * var(--svh));
          align-content: flex-start;
        }

        .ProjectsGrid > .wrapper > div {
          display: grid;
          --cols: 2;
          grid-template-columns: repeat(var(--cols), 1fr);
        }

        @media (min-width: 480px) {
          .ProjectsGrid > .wrapper > div {
            --cols: 2;
          }
          .ProjectsGrid figure:nth-last-child(1) {
          }
        }
        @media (min-width: 768px) {
          .ProjectsGrid > .wrapper > div {
            --cols: 3;
          }
          .ProjectsGrid figure:nth-last-child(-n + 3) {
          }
        }
        @media (min-width: 1024px) {
          .ProjectsGrid > .wrapper > div {
            --cols: 3;
          }
          .ProjectsGrid figure:nth-last-child(-n + 3) {
          }
        }
        @media (min-width: 1440px) {
          .ProjectsGrid > .wrapper > div {
            --cols: 3;
          }
          .ProjectsGrid figure:nth-last-child(-n + 3) {
          }
        }
        @media (min-width: 1680px) {
          .ProjectsGrid > .wrapper > div {
            --cols: 4;
          }
          .ProjectsGrid figure:nth-last-child(-n + 4) {
          }
        }
        @media (min-width: 1920px) {
          .ProjectsGrid > .wrapper > div {
            --cols: 4;
          }
          .ProjectsGrid figure:nth-last-child(-n + 4) {
          }
        }

        .ProjectsGrid[data-view="small"] > .wrapper > div {
          --cols: 3;
        }

        @media (min-width: 480px) {
          .ProjectsGrid[data-view="small"] > .wrapper > div {
            --cols: 3;
          }
        }
        @media (min-width: 768px) {
          .ProjectsGrid[data-view="small"] > .wrapper > div {
            --cols: 4;
          }
        }
        @media (min-width: 1024px) {
          .ProjectsGrid[data-view="small"] > .wrapper > div {
            --cols: 4;
          }
        }
        @media (min-width: 1440px) {
          .ProjectsGrid[data-view="small"] > .wrapper > div {
            --cols: 6;
          }
        }
        @media (min-width: 1680px) {
          .ProjectsGrid[data-view="small"] > .wrapper > div {
            --cols: 6;
          }
        }
        @media (min-width: 1920px) {
          .ProjectsGrid[data-view="small"] > .wrapper > div {
            --cols: 8;
          }
        }

        .ProjectsGrid .Media {
          width: 100%;
          height: auto;
          aspect-ratio: 4/5;
          transition: 0.5s filter;
          pointer-events: none;
        }

        .ProjectsGrid .Media mux-player {
          --controls: none;
        }

        .ProjectsGrid .wrapper > div {
        }

        @media (hover: hover) {
          .ProjectsGrid figure:hover .Media {
            filter: sepia(0.05) contrast(1.4) hue-rotate(120deg) brightness(0.9);
            transition: filter 0.233s;
            animation: blurAnimation 1.5s forwards;
            cursor: pointer;
          }

          .ProjectsGrid figure:hover .Media::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #a6b0b9;
            mix-blend-mode: luminosity;
            opacity: 1;
            z-index: 10;
          }
        }

        .ProjectsGrid figure:active .Media {
          filter: sepia(0.05) contrast(2.5) hue-rotate(120deg) brightness(0.7);
          transition: filter 0.233s;
          animation: blurAnimation 1.5s forwards;
          cursor: pointer;
        }

        .ProjectsGrid figure:active .Media::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #a6b0b9;
          mix-blend-mode: luminosity;
          opacity: 1;
          z-index: 10;
        }

        .ProjectsGrid figure {
          display: block;
          cursor: pointer;
          width: calc((100vw - var(--w-scrollbar)) / var(--cols));
          scroll-snap-align: start;
          position: relative;
          contain: size layout paint style;
          content-visibility: auto;
          aspect-ratio: 4/5;
          contain-intrinsic-size: calc(
            ((100vw - var(--w-scrollbar)) / var(--cols)) / 4 * 5
          );
        }

        .ProjectsGrid figcaption {
          position: absolute;
          bottom: var(--dist-md);
          left: var(--dist-md);
          width: calc(100% - 2 * var(--dist-md));
          background: var(--bg-glass);
          backdrop-filter: blur(3px) brightness(0.9) hue-rotate(120deg);
          border-radius: 1px;
          z-index: 7;
          transition: backdrop-filter 0.4s;

          padding: var(--dist-md);
          display: flex;
          justify-content: center;
          gap: var(--dist-md);
          pointer-events: auto;
        }

        @media (hover: hover) {
          .ProjectsGrid figure:hover figcaption {
            backdrop-filter: blur(3px) brightness(0.6) hue-rotate(120deg)
              contrast(1.2);
          }
        }

        .ProjectsGrid figure:active figcaption {
          backdrop-filter: blur(3px) brightness(0.1) hue-rotate(120deg)
            contrast(1.2);
        }

        .ProjectsGrid figcaption span {
          white-space: nowrap;
        }

        .ProjectsGrid figcaption span.clients {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media (max-width: 767px) {
          .ProjectsGrid > .wrapper {
            scroll-padding-top: var(--global-offset-y);
            padding-top: var(--global-offset-y);
          }

          .ProjectsGrid[data-view="small"] figcaption {
            display: none !important;
          }

          .ProjectsGrid figcaption span:not(.clients) {
            display: none !important;
          }
        }

        .ProjectsGrid footer {
          width: 100%;
          height: var(--global-offset-y);
          padding: var(--dist-md);
          display: grid;
          gap: calc(2 * var(--dist-md));
          grid-template-columns: 1fr 1fr;
          background: hsla(0 0% 0% / 0.9);
        }

        .ProjectsGrid footer button {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: center;
        }

        .ProjectsGrid footer button:last-child {
          justify-content: flex-end;
        }

        .ProjectsGrid footer button[data-disabled="true"] {
          opacity: 0.5;
        }

        .ProjectsGrid footer button span {
          min-width: 10ch;
          text-align: center;
          background: var(--bg-glass);
          border-radius: 1px;
          backdrop-filter: blur(3px) brightness(0.9) hue-rotate(120deg);
          display: flex;
          justify-content: center;
          gap: var(--dist-md);
          padding: var(--dist-md);
          transition: backdrop-filter 0.4s;
        }

        @media (hover: hover) {
          .ProjectsGrid footer button span:hover {
            backdrop-filter: blur(3px) brightness(0.6) hue-rotate(120deg)
              contrast(1.2);
          }
        }

        .ProjectsGrid footer button span:active {
          backdrop-filter: blur(3px) brightness(0.3) hue-rotate(120deg)
            contrast(1.2);
        }
      `}</style>
    </>
  );
};

export default ProjectsGrid;
