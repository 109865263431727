import animateScrollTo from "animated-scroll-to";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { shallow } from "zustand/shallow";

import { DUR_MD, DUR_SM } from "@/config";
import { useHashStore } from "@/lib/store";

import HomeContainer from "./HomeContainer";
import HomeLabel from "./HomeLabel";

const Home = ({ page, ...props }) => {
  const { home: projects } = page || {};

  const ref = useRef();

  const { setProject, setInfo } = useHashStore((state) => state, shallow);

  const [iPage, setPage] = useState(0);

  const onTap = ({ clientX, clientY }) => {
    const element1 = document
      ?.querySelectorAll(".HomeContainer[data-pos='left']")?.[0]
      ?.querySelectorAll(".preview[data-active='before'] .Media")?.[0];
    const element2 = document
      ?.querySelectorAll(".HomeContainer[data-pos='right']")?.[0]
      ?.querySelectorAll(".preview[data-active='before'] .Media")?.[0];

    const rect1 = element1?.getBoundingClientRect();
    const rect2 = element2?.getBoundingClientRect();

    const clickedWithinElement =
      (clientX >= rect1?.left &&
        clientX <= rect1?.right &&
        clientY >= rect1?.top &&
        clientY <= rect1?.bottom) ||
      (clientX >= rect2?.left &&
        clientX <= rect2?.right &&
        clientY >= rect2?.top &&
        clientY <= rect2?.bottom);

    setInfo(null);

    clickedWithinElement
      ? animateScrollTo(ref?.current?.querySelectorAll(".HomeItem")?.[iPage], {
          elementToScroll: ref?.current,
          verticalOffset: -50,
          cancelOnUserAction: false,
        })
      : setProject(projects?.[iPage]?.slug?.current);
  };

  return (
    <>
      <motion.article
        ref={ref}
        className="Home"
        key="home"
        onTap={onTap}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        transition={{
          duration: DUR_MD,
          delay: DUR_SM,
          ease: "easeOut",
        }}
      >
        {projects?.map((p, i) => {
          return (
            <motion.div
              key={i}
              className="HomeItem"
              data-active={i === iPage}
              onViewportEnter={() => {
                setPage(i);
              }}
              viewport={{
                margin: "-49% 0%",
              }}
            />
          );
        })}
      </motion.article>

      {projects?.map((project, i) => {
        return (
          <HomeLabel
            key={i}
            project={project}
            isActive={i === iPage}
            wasActive={i <= iPage}
            iPage={iPage}
          />
        );
      })}

      <HomeContainer key="left" projects={projects} pos="left" iPage={iPage} />
      <HomeContainer
        key="right"
        projects={projects}
        pos="right"
        iPage={iPage}
      />

      <style jsx global>{`
        html {
          overflow: hidden;
        }

        html,
        body {
          background: white;
        }

        .Home {
          position: relative;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100 * var(--svh));
          z-index: 0;

          cursor: pointer;
          overflow-y: auto;
          scroll-snap-type: y mandatory;
          --h-sentinel: calc(50 * var(--svh));
          z-index: 111;
          padding-top: calc(38 * var(--svh));
          padding-bottom: calc(38 * var(--svh));
        }

        .Home .HomeItem {
          width: calc(100%);
          height: var(--h-sentinel);
          scroll-snap-align: center;
          scroll-snap-stop: always;
          pointer-events: none;
        }
      `}</style>
    </>
  );
};

export default Home;
